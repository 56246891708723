import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "modules/pages/Home";
import Careers from "modules/pages/Careers";
import { JobDetails } from "modules/pages/JobDetails";
import BlogEntryTemplate from "modules/components/BlogEntryTemplate";
import PerksAndBenefits from "modules/pages/PerksAndBenefits";
import JobTemplate from "modules/components/JobTemplate/JobTemplate.tsx";

export default (
  <Switch>
    <Route component={Home} exact path="/" />
    <Route component={JobTemplate} path="/jobs/:id" />
    <Route component={Careers} path="/jobs" />
    <Route component={JobDetails} path="/job/:id" />
    <Route component={BlogEntryTemplate} path="/blog/:id" />
    <Route path="/blog">
      <Redirect to="/"></Redirect>
    </Route>
    <Route path="/careers">
      <Redirect to="/jobs"></Redirect>
    </Route>
    <Route component={PerksAndBenefits} path="/perksandbenefits" />
  </Switch>
);
