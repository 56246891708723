import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@match/react-component-library";
import { MatchJobPostingGrouped } from "types";

interface JobsListProps {
  jobsGrouped: MatchJobPostingGrouped[];
}

export default function JobsList(props: JobsListProps) {
  const { jobsGrouped } = props;
  return (
    <Box>
      {jobsGrouped.map((team) => {
        return (
          <Box key={team.group} paddingY="smedium">
            <Box>
              <Box
                key={team.group}
                display="flex"
                justifyContent="space-between"
              >
                <Box>
                  <Typography>{team.group}</Typography>
                </Box>
              </Box>
            </Box>
            {team.jobs.map((job, index) => {
              return (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  paddingY="xsmall"
                >
                  <Box paddingLeft="medium">
                    <Link to={`/job/${job.id}`}>
                      <Typography fontSize="xsmall" color="link">
                        {job.title}
                      </Typography>
                    </Link>
                  </Box>
                  <Box textAlign="right">
                    <Typography fontSize="xsmall">{job.location}</Typography>
                    &nbsp;-&nbsp;
                    <Typography fontSize="xsmall">
                      {job.workplaceType}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
}
