import React from "react";
import styled from "@emotion/styled";
import SocialLinks from "modules/components/SocialLinks";
import { Box, Typography } from "@match/react-component-library";
import { useResponsiveContext } from "../../../utils/ResponsiveContext";

interface PageHeaderProps {
  pageTitle: string;
}

export default function PageHeader(props: PageHeaderProps) {
  const isMobile = useResponsiveContext().mobile;
  return (
    <React.Fragment>
      <StyledHeader
        alignItems="center"
        backgroundColor="ctaPrimary"
        display="flex"
        height="50px"
        justifyContent="center"
        position="fixed"
        width="100%"
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width={isMobile ? "90vw" : "70vw"}
        >
          <Typography color="light">{props.pageTitle}</Typography>
          {!isMobile && <SocialLinks size="16px" color="rgb(255, 255, 255)" />}
        </Box>
      </StyledHeader>
      <Box height="50px"></Box>
    </React.Fragment>
  );
}

const StyledHeader = styled(Box)(() => {
  return {
    zIndex: 10,
  };
});
