export const SLIDE_PHOTO_ENTRIES = [
  {
    id: 0,
    image: "//www.lifeatmatch.com/photo-1546015720-b8b30df5aa27.jpeg",
    title: "Time off for mommy and daddy. At 100% pay. Oh, baby!",
  },
  {
    id: 1,
    image: "//www.lifeatmatch.com/photo-1558929996-da64ba858215.jpeg",
    title:
      "We've got a 401k for you, insurance for your pet, and everything in between.",
  },
  {
    id: 3,
    image: "//www.lifeatmatch.com/photo-1530847887473-36dbaf586122.jpeg",
    title:
      "In addition to all the regular holidays, we have a holiday called National You Day!",
  },
  {
    id: 3,
    image: "//www.lifeatmatch.com/photo-1554415707-6e8cfc93fe23.jpeg",
    title:
      "Never. Stop. Learning. We are are proud to offer the Be Your Best Self Program.",
  },
];