// react-app-polyfill/ie11 provides support to create-react-app for IE11
import 'react-app-polyfill/ie11';
// 'core-js/es7/object' is the only core-js polyfill needed for this
// template to function in ie11. If you experience unexplained JS errors specific
// to older browsers, you may need to add a new import. 
// For documentation on core-js, see their github page at:
// https://github.com/zloirock/core-js
import 'core-js/es/object';
import 'core-js/features/array/includes';
import 'core-js/features/string/includes';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

ReactDOM.render(<Router><App /></Router>, document.getElementById('root'));

if (module.hot) {
  module.hot.accept('./App', () => {
    ReactDOM.render(
      <App />,
      document.getElementById('root') as HTMLElement
    );
  });
}