import React from "react";
import VideoPlayer from "modules/components/VideoPlayer";
// import MatchSocial from "modules/components/MatchSocial";
import { Box, Image, Typography, Button } from "@match/react-component-library";
import { Link } from "react-router-dom";
import { useResponsiveContext } from "../../../utils/ResponsiveContext";

export default function Home() {
  const isMobile = useResponsiveContext().mobile;
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box position="relative" width="100vw">
          <VideoPlayer />
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            height={isMobile ? "100%" : "80vh"}
            justifyContent="center"
            position="absolute"
            top="none"
            width="100%"
          >
            <Box marginBottom="large">
              <Typography
                color="light"
                css={{
                  fontSize: isMobile ? "28px" : "72px",
                  textShadow: "2px 2px 8px #3f3e3e",
                }}
                fontWeight="ultraLight"
                fontFamily="heading"
              >
                Do what you love
              </Typography>
            </Box>
            <Link to="/jobs">
              <Button color="core1_100" size={isMobile ? "small" : "large"}>
                See All Openings
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        css={{ marginTop: "48px", marginBottom: "48px" }}
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          css={{ marginBottom: "48px" }}
        >
          <Typography
            color="textPrimary"
            css={{ fontSize: isMobile ? "32px" : "40px" }}
            fontWeight="ultraLight"
            textAlign="center"
            fontFamily="heading"
          >
            Your strengths, our opportunities– <br /> a perfect match.
          </Typography>
        </Box>
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
        >
          {/* <MatchSocial /> */}
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            marginBottom="smedium"
            width="100%"
          >
            <Image
              alt="Life At Match"
              src="/uploads/lifeatmatchlogo.jpg"
              width="50px"
            ></Image>
            <Typography>lifeatmatch</Typography>
          </Box>
          <Box margin="auto">
            <a
              href="https://www.instagram.com/lifeatmatch/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button color="core1_100">Follow on Instagram</Button>
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
