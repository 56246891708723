import React from 'react';
import SocialLinks from 'modules/components/SocialLinks';
import { Box, Typography } from "@match/react-component-library";
import { FaRegCopyright } from 'react-icons/fa';

export default function Footer() {
  return (
    <Box
      width="100vw"
      height="150px"
      backgroundColor="dark"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box paddingY="xlarge" marginBottom="medium">
        <SocialLinks size="1em" color="rgb(255, 255, 255)" />
      </Box>
      <Typography fontSize="xxxsmall" color="disabled">
        <FaRegCopyright /> 2019 Life At Match
      </Typography>
    </Box>
  );
}
