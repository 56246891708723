import React from 'react';
import { useMediaQuery } from 'react-responsive' 

export const ResponsiveContext = React.createContext({ mobile: false });

export const ResponsiveContextProvider = (props: React.PropsWithChildren<{}>) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  });

  return (
    <ResponsiveContext.Provider
      value={{ mobile: isMobile }}
    >
      {props.children}
    </ResponsiveContext.Provider>
  )
}

export function useResponsiveContext() {
  return React.useContext(ResponsiveContext);
}