import React from 'react'
import ReactPlayer from 'react-player';

export default function VideoPlayer() {
  return (
    <React.Fragment>
      <ReactPlayer
        width='100%'
        height='50%'
        url='uploads/2017/08/careers2017-2.mp4' 
        playing
        loop
        muted
      />
    </React.Fragment>     
  )
}



