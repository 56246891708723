import React from 'react';
import styled from '@emotion/styled';
import SocialLinks from 'modules/components/SocialLinks';
import { BLOG_ENTRY_LINKS } from 'static-data/blog-link-entries';
import { Box, Typography } from "@match/react-component-library";
import { NavLink } from 'react-router-dom';

export default function BlogSideBar() {

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js'
    document.getElementsByClassName('twitter-embed')[0].appendChild(script);
  }, []);

  return (
    <Box>
      <SocialLinks size="1.5em" color="rgba(26, 27, 28, 0.7)" />
      <Box marginTop="medium">
        <Typography fontSize="large" fontWeight="bold">
          Recent Posts
        </Typography>
        <Box>
          {BLOG_ENTRY_LINKS.filter((entry) => entry.id < 5).map((entry) => {
            return (
              <React.Fragment key={entry.id}>
                <StyledLink to={`/blog/${entry.id}`}>
                  <TruncatedTextContainer>
                    <Typography color="textPrimary">{entry.title}</Typography>
                  </TruncatedTextContainer>
                </StyledLink>
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
      <Box>
        <Box marginY="medium">
          <Typography fontSize="large" fontWeight="bold">
            On Twitter
          </Typography>
        </Box>
        <Box className="twitter-embed">
          <a
            className="twitter-timeline"
            data-theme="light"
            data-tweet-limit="4"
            data-chrome="noheader nofooter noborders"
            href="https://twitter.com/lifeatmatch?ref_src=twsrc%5Etfw"
          >
            LifeAtMatch on Twitter
          </a>
        </Box>
      </Box>
    </Box>
  );
}

const TruncatedTextContainer = styled(Box)(() => {
  return {
    maxWidth: '300px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '10px',
    margin: 0,
    resize: 'horizontal',
  }
})

const StyledLink = styled(NavLink)(() => {
  return {
    textDecoration: 'none'
  }
});