import React from "react";
import styled from "@emotion/styled";
import Link from "modules/components/Header/Link";
import SlideInMenu from "modules/components/SlideInMenu";
import useWindowSize from "utils/useWindowSize";
import { Box, Typography } from "@match/react-component-library";
import { FaBars } from "react-icons/fa";
import { BP } from "breakpoints";

export default function Header() {
  const [width] = useWindowSize();
  const [slideMenuOpen, setSlideMenuOpen] = React.useState(false);
  const toggleMenu = (): void => {
    setSlideMenuOpen(!slideMenuOpen);
  };
  return (
    <StyledHeader
      alignItems="center"
      backgroundColor="light"
      borderBottom="1px solid"
      borderColor="stroke"
      display="flex"
      height="70px"
      justifyContent="center"
      position="fixed"
      top="none"
      width="100vw"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width={width > BP.XLARGE ? "75vw" : "90vw"}
      >
        <Link color="textPrimary" exact to="/">
          <img
            alt="Life At Match"
            src={window.location.origin + "/uploads/lifeatmatchlogo2021.png"}
            width={72}
          />
        </Link>
        {width <= BP.LARGE ? (
          <FaBars onClick={toggleMenu} size="1.5em" />
        ) : (
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="500px"
          >
            <Link color="textPrimary" exact to="/">
              home
            </Link>
            <Link color="textPrimary" to="/jobs">
              careers
            </Link>
            <Link color="textPrimary" to="/perksandbenefits">
              perks + benefits
            </Link>
            <ExternalLink href="https://mtch.com" target="_blank">
              <Typography color="textPrimary" fontSize="xsmall">
                about us
              </Typography>
            </ExternalLink>
          </Box>
        )}
        <SlideInMenu slideMenuOpen={slideMenuOpen} toggleMenu={toggleMenu} />
      </Box>
    </StyledHeader>
  );
}

const StyledHeader = styled(Box)(() => {
  return {
    zIndex: 100,
  };
});
const ExternalLink = styled("a")(() => {
  return {
    textDecoration: "none",
    padding: "10px",
    "&:hover": {
      textDecoration: "underline",
    },
  };
});
