export const BLOG_ENTRY_LINKS = [
  {
    id: 0,
    title: 'Employee Spotlight - Kickin\' it with Clint',
    subtitle: '',
    image: 'uploads/2019/03/clintThumb.jpg',
  },
  {
    id: 1,
    title: 'Happy Valentine\'s Day!',
    subtitle: '',
    image: 'uploads/2019/02/Vday.jpg',
  },
  {
    id: 2,
    title: 'Jared Sine leads Match\'s \'world class\' legal department',
    subtitle: '',
    image: 'uploads/2019/02/jaredThumb.jpg',
  },
  {
    id: 3,
    title: 'Betches Is Launching a New Dating App That Lets You Pick Dates for Your Friends',
    subtitle: '',
    image: 'uploads/2019/01/thumbShip.png',
  },
  {
    id: 4,
    title: 'What\'s it like to intern at Match!?',
    subtitle: '',
    image: 'uploads/2019/01/internatmatch.png',
  },
  {
    id: 5,
    title: 'The CEO Behind Tinder, OkCupid on the Future of Online Dating',
    subtitle: '',
    image: 'http://staging.lifeatmatch.com/wp-content/uploads/2017/07/ceo.png',
  },
  {
    id: 6,
    title: 'Our CEO audited the company’s payroll to make sure women were being paid equally…and the results were amazing!',
    subtitle: '',
    image: 'uploads/2018/12/thumbMandy.jpg',
  },
  {
    id: 7,
    title: 'Employee Spotlight - A chat with Kenyatta',
    subtitle: '',
    image: 'uploads/2018/05/K1Thumb.jpg',
  },
  {
    id: 8,
    title: 'Mentoring at HackUTD',
    subtitle: '',
    image: 'uploads/2018/02/HackUTD2.jpeg',
  },
  {
    id: 9,
    title: 'Happy Holidays From Lifeatmatch!',
    subtitle: '',
    image: 'uploads/2018/01/1.jpg',
  },
  {
    id: 10,
    title: 'Tech Events & Match - 2017',
    subtitle: '',
    image: 'uploads/2017/12/Chef3.jpg',
  },
  {
    id: 11,
    title: 'Congrats to our very own Mandy Ginsberg, a D Magazine finalist for CEO of the Year!',
    subtitle: '',
    image: 'http://staging.lifeatmatch.com/wp-content/uploads/2017/07/ceo.png',
  },
  {
    id: 12,
    title: '? Happy Halloween!! ?',
    subtitle: 'Spooky Fun At Our 2017 Halloween Party',
    image: 'uploads/2017/11/happy-halloween.png',
  },
  {
    id: 13,
    title: 'Match Sponsers HackUTA',
    subtitle: 'We had a blast at UTA this weekend!',
    image: 'http://staging.lifeatmatch.com/wp-content/uploads/2017/10/thumb_hackuta.png',
  },
  {
    id: 14,
    title: 'BYKTWD 2017!',
    subtitle: 'Bring Your Kids To Work Day 2017',
    image: 'http://staging.lifeatmatch.com/wp-content/uploads/2016/03/CS_6ldlVEAA1fEf.jpg',
  },
  {
    id: 15,
    title: 'CEO Interview - Mandy Ginsberg',
    subtitle: 'Check out this interview with our CEO, Mandy',
    image: 'http://staging.lifeatmatch.com/wp-content/uploads/2017/07/ceo.png',
  },
  {
    id: 16,
    title: 'Our 2017 Summer Party Was ???',
    subtitle: 'We all took a trip up to the lake for this years Summer Party!',
    image: 'http://staging.lifeatmatch.com/wp-content/uploads/2017/08/summerThumb.png',
  },
  {
    id: 17,
    title: 'Missed Connections',
    subtitle: 'We\'re thrilled for Match\'s newest major feature release, Missed Connections.',
    image: 'uploads/2017/10/thumb-missedConections.png',
  },
  {
    id: 18,
    title: 'Match Employees Love Their Parents',
    subtitle: 'We asked our parents a few questions when they stopped by for our annual "Bring your Parents to Work Day!" Check out what they said!',
    image: 'http://staging.lifeatmatch.com/wp-content/uploads/2017/08/matchParents.png',
  },
]
