import React from 'react';
import styled from '@emotion/styled';
import DarkOverlay from 'modules/components/DarkOverlay';
import { Box, Typography } from "@match/react-component-library";
import { BP } from 'breakpoints';

interface SlidePhotoEntryProps {
  entryTitle: string;
  entryImage: string;
}

export default function SlidePhotoEntry(props: SlidePhotoEntryProps) {
  return (
    <Box position="relative" textAlign="center">
      <SlideImage src={props.entryImage} alt={props.entryTitle} />
      <DarkOverlay />
      <SlideTitleContainer position="absolute" width="100%" height="auto">
        <Typography color="light" fontSize="small" fontWeight="bold">
          {props.entryTitle}
        </Typography>
      </SlideTitleContainer>
    </Box>
  );
}

const SlideImage = styled('img')(() => {
  return {
    width: '375px',
    height: 'auto',
    [`@media (min-width: ${BP.SMALL}px)`]: {
      width: '475px',
    },
    [`@media (min-width: ${BP.MEDIUM}px)`]: {
      width: '600px',
    },
    [`@media (min-width: ${BP.LARGE}px)`]: {
      width: '800px',
    },
    [`@media (min-width: ${BP.XLARGE}px)`]: {
      width: '1000px',
    }
  }
});

const SlideTitleContainer = styled(Box)(() => {
  return {
    bottom: '5%',
    left: '50%',
    transform: ['translate(-50%, -50%)'],
    textAlign: 'center',
    minWidth: '150px',
    zIndex: 10
  }
})

