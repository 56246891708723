import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@match/react-component-library';

export default function DarkOverlay() {
  return (
    <DarkOverlayContainer
      position='absolute'
      width='100%'
      height='100%'
      backgroundColor='dark'
    >
      {' '}
    </DarkOverlayContainer>
  );
}

const DarkOverlayContainer = styled(Box)(() => {
  return {
    top: '50%',
    left: '50%',
    transform: ['translate(-50%, -50%)'],
    textAlign: 'center',
    minWidth: '150px',
    opacity: 0.25,
  }
})
