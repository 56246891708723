import React from 'react';
import styled from '@emotion/styled';
import useWindowSize from 'utils/useWindowSize';
import BlogSideBar from 'modules/components/BlogSideBar';
import { Box, Typography } from "@match/react-component-library";
import { BLOG_ENTRIES } from 'static-data/blog-entries';
import { BP } from 'breakpoints';

export default function BlogEntryTemplate(props: any) {
  const [ width ] = useWindowSize();
  const { id } = props.match.params
  const currentEntry = BLOG_ENTRIES[id];
  const html = { __html: currentEntry.cleanText };

  return (
    <Box>
      <Box
        alignItems={width <= BP.MEDIUM ? "center" : undefined}
        display="flex"
        flexDirection={width <= BP.MEDIUM ? "column" : "row"}
        justifyContent="center"
        padding="smedium"
      >
        <Box
          borderColor={width > BP.MEDIUM ? "stroke" : undefined}
          borderRight={width > BP.MEDIUM ? "1px solid" : undefined}
          marginRight={width > BP.MEDIUM ? "xxlarge" : undefined}
          paddingRight={width > BP.MEDIUM ? "xxlarge" : undefined}
          width={
            width <= BP.MEDIUM
              ? "350px"
              : width <= BP.LARGE
              ? "450px"
              : width <= BP.XLARGE
              ? "550px"
              : "650px"
          }
        >
          <Box display="flex" flexDirection="column">
            <Typography fontSize="xxlarge">{currentEntry.title}</Typography>
            <Box marginTop="small" marginBottom="medium">
              <Typography fontSize="small">
                {currentEntry.date} | LifeAtMatch
              </Typography>
            </Box>
            {currentEntry.hero &&
            (currentEntry.hero.includes("https://player.vimeo.com/") ||
              currentEntry.hero.includes("https://video-api.wsj.com")) ? (
              <StyledIFrame
                src={currentEntry.hero}
                width={
                  width <= BP.MEDIUM
                    ? "350px"
                    : width <= BP.LARGE
                    ? "400px"
                    : width <= BP.XLARGE
                    ? "500px"
                    : "600px"
                }
                height={
                  width <= BP.MEDIUM
                    ? "200px"
                    : width <= BP.LARGE
                    ? "250px"
                    : "350px"
                }
                data-frameborder="0"
                data-webkitallowfullscreen
                data-mozallowfullscreen
              ></StyledIFrame>
            ) : (
              <img
                alt={currentEntry.title}
                src={window.location.origin + currentEntry.hero}
                width={
                  width <= BP.MEDIUM
                    ? "350px"
                    : width <= BP.LARGE
                    ? "400px"
                    : width <= BP.XLARGE
                    ? "500px"
                    : "600px"
                }
                height="auto"
              />
            )}
            <Box marginY="medium">
              <Typography>
                <div dangerouslySetInnerHTML={html} />
              </Typography>
            </Box>
          </Box>
        </Box>
        <BlogSideBar />
      </Box>
    </Box>
  );
}

const StyledIFrame = styled('iframe')(() => {
  return {
    border: 'none'
  }
}) 
