import React from "react";
import styled from "@emotion/styled";
import { Box, Typography } from "@match/react-component-library";

interface PerksAndBenefitsEntryProps {
  entryTitle: string;
  entryDescription: string;
  entryImage: string;
}

export default function PerksAndBenefitsEntry(
  props: PerksAndBenefitsEntryProps
) {
  return (
    <EntryContainer
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      maxHeight={275}
      maxWidth={250}
      minHeight={275}
      minWidth={250}
      margin="8px"
    >
      <EntryTitle position="absolute" textAlign="center">
        <Typography>{props.entryTitle}</Typography>
      </EntryTitle>
      <EntryImage src={props.entryImage} alt={props.entryTitle} />
      <EntryOverlay backgroundColor="ctaPrimary" padding="small">
        <Box lineHeight="1">
          <Typography color="light" fontSize="xsmall">
            {props.entryDescription}
          </Typography>
        </Box>
      </EntryOverlay>
    </EntryContainer>
  );
}

const EntryContainer = styled(Box)(() => {
  return {
    position: "relative",
    width: "50%",
  };
});
const EntryImage = styled("img")(() => {
  return {
    display: "block",
    minWidth: "250px",
    width: "100%",
    height: "auto",
  };
});
const EntryOverlay = styled(Box)(() => {
  return {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    opacity: 0,
    transition: "0.5s ease",
    "&:hover": {
      opacity: 1,
    },
  };
});
const EntryTitle = styled(Box)(() => {
  return {
    top: "15%",
    left: "50%",
    transform: ["translate(-50%, -50%)"],
    textAlign: "center",
    minWidth: "150px",
  };
});
