import React from 'react';
import { Box } from '@match/react-component-library';
import Header from '../Header';
import Footer from '../Footer';
interface LayoutProps {
  children: React.ReactChildren | React.ReactChild;
}

function Layout({
  children
}: LayoutProps) {
  return (
    <React.Fragment>
      {
        // wrapping this box gives the offset for the header
      }
      <Box height={70}>
        <Header />
      </Box>
      {children}
      <Footer />
    </React.Fragment>
  );
}

export default Layout;
