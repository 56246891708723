export const PERKS_BENEFITS_ENTRIES = [
  {
    id: 0,
    title: 'You Do You',
    description: 'We offer Paid Time Off. We also have you covered with plenty of holidays throughout the year!',
    type: 'perk',
    image: 'uploads/2016/07/calendar2.png',
  },
  {
    id: 1,
    title: 'Be Healthy',
    description: 'What good is a healthy bottom line without a happy state of mind? We offer free in-office yoga classes weekly to help you stay centered. We also have a free gym in our building! Come and get your sweat on! We also encourage you to get out of the office and into a good sweat by offering discounts at select fitness facilities.',
    type: 'perk',
    image: 'uploads/2016/07/workout-1.png',
  },
  {
    id: 2,
    title: 'Honk Honk!',
    description: 'We try to make getting to work as easy as possible by offering pretax opportunities to purchase mass transit or parking passes. We also help take the sting out of your wallet with partial reimbursements and the occasional work from home day.',
    type: 'perk',
    image: 'uploads/2016/07/parking-1.png',
  },
  {
    id: 3,
    title: 'Dress Like Yourself',
    description: 'You can wear a tie if you like, or flip flops and a hoodie, or all of the above. We’re pretty laid back when it comes to dress. If it makes you feel good, go for it.',
    type: 'perk',
    image: 'uploads/2016/07/casual-1.png'
  },
  {
    id: 4,
    title: 'Team Building (that doesn\'t suck)',
    description: 'Have you ever gone to one of those team building retreats in the woods where they ask you to fall back backwards into the arms of your coworkers? That won’t be happening here. We prefer ping pong, video games, pool, foosball and nerf wars. You better bring your best game though, we’re competitive.',
    type: 'perk',
    image: 'uploads/2016/07/games-1.png'
  },
  {
    id: 5,
    title: 'Drop Our Name \nAround Town',
    description: 'We may be a multi-national tech company, but we’re still a startup at heart. As such, we love getting involved with fellow tech companies and local businesses to bring our employees discounts on the goods and services to make their lives easier. From on-site dry cleaning to mobile phones. From lunch to a new laptop, there are a plenty of treats and discounts that go along with joining the Match family.',
    type: 'perk',
    image: 'uploads/2016/07/locations-1.png'
  },
  {
    id: 6,
    title: 'Get Smart',
    description: 'Never. Stop. Learning. Some smarty once said, “Chance favors the prepared mind” and we couldn’t agree more. As such, we are glad to offer the “Be your Best Self” program!',
    type: 'perk',
    image: 'uploads/2016/07/cash.png'
  },
  {
    id: 7,
    title: 'Work With Friends',
    description: 'Want to help take some of the legwork out of recruitment? Great! Introduce us to your brilliant friends and we’ll give you a bonus if things work out. 😉',
    type: 'perk',
    image: 'uploads/2016/07/chat.png'
  },
  {
    id: 8,
    title: 'Snacks',
    description: 'We take snacks seriously. We have fresh fruit, a cereal bar, yogurt, tea, coffee and all kinds of other healthy options for you on the reg. Also, baked goods and sweets have been known to appear in the kitchen from time to time. Oh, and we have bagels every Friday.',
    type: 'perk',
    image: 'uploads/2016/07/snacks.png'
  },
  {
    id: 9,
    title: 'We\'ll Celebrate Anything',
    description: 'We like to celebrate: Product launches, nice weather, a new flavor of La Croix… we’ll take any opportunity to get together for some fun. From happy hours and summer soirees to our annual holiday party, there’s always something fun to look forward to.',
    type: 'perk',
    image: 'uploads/2016/07/celebrate.png'
  },
  {
    id: 10,
    title: 'Do Good.',
    description: 'If it’s one thing we understand, it’s passion. We’re glad to give our employees a day of their choosing each year to spend contributing to whatever cause or charity gets them going. Whether it’s picking up litter in the park or chaining yourself to a bulldozer, we want to make sure you’ve got the opportunity to help make the world a better place.',
    type: 'perk',
    image: 'uploads/2016/07/handshake.png'
  },
  {
    id: 11,
    title: 'Medical Benefits',
    description: 'We\'ve got plenty of great medical plans for you to choose from!',
    type: 'benefit',
    image: 'uploads/2016/07/medical.png'
  },
  {
    id: 12,
    title: '401k',
    description: 'To help plan ahead, we offer sponsored pre-tax & Roth 401k plans. Oh, and at Match we match.',
    type: 'benefit',
    image: 'uploads/2016/07/piggy-bank2.png'
  },
  {
    id: 13,
    title: 'Life Insurance',
    description: 'We provide life insurance equal to 1.5x your annual salary (up to $500k) at no cost. Coverage also covers your spouse, domestic partner and kids, because family is family & family’s important.',
    type: 'benefit',
    image: 'uploads/2016/07/piggybank.png'
  },
  {
    id: 14,
    title: 'Disability',
    description: 'Oh baby it’s a wild world, which is why we offer short & long term disability plans that replace up to 60% of lost income at no cost to you, should something happen while you’re yoloing.',
    type: 'benefit',
    image: 'uploads/2016/07/shield.png'
  },
]