import axios, { AxiosResponse } from "axios";
import {
  MatchJobPosting,
  MatchJobPostingGrouped,
  MatchJobDetailsSection,
} from "types";
import { LeverPosting, LeverPostingsByTeam, LeverSalary } from "types/leverApi";

const API_URL = "https://api.lever.co/v0/";
const URLS = {
  JOBS: "postings/matchgroup?mode=json&group=team&department=Match%20%2F%20Match%20Affinity&department=Match%20Group&department=Match%2C%20Match%20Affinity%2C%20and%20Match%20Group&department=Match&department=Affinity&department=Plus%201%20Labs&department=The%20League",
  JOB_DETAILS: "postings/matchgroup/",
};

const TEAM_MAP: {
  [key: string]: string;
} = {
  Accounting: "Accounting & Finance",
  "Affinity - Analytics": "Analytics",
  "Affinity - Marketing + PR": "Marketing + PR",
  "Affinity - Care": "Care",
  "Affinity - Internships": " Internships",
  "Affinity - Product": "Product Design",
  "Affinity - Engineering": "Engineering",
  "Affinity - Product Design": "Product Design",
  Finance: "Accounting & Finance",
  Analytics: "Analytics & Strategy",
  Care: "Care",
  Communications: "Communications",
  "Engineering - Match": "Engineering",
  "Engineering - +1 Labs": "Engineering",
  "Engineering - Match Group": "Engineering",
  Legal: "Legal",
  "Match - Analytics": "Analytics",
  "Match - Care": "Care",
  "Match - Engineering": "Engineering",
  "Match - Internships": "Internships",
  "Match - Product": "Product Design",
  "Match - Product Design": "Product Design",
  "Match - Marketing + PR": "Marketing + PR",
  "MG EMEA": "MG EMEA",
  "Plus 1 Labs": "Plus 1 Labs",
  Product: "Product",
  "Product Design": "Product",
  "Real Estate & Facilities": "Real Estate & Facilities",
  Security: "Security",
  Strategy: "Analytics & Strategy",
  Tax: "Tax",
  "The League": "The League"
};

const axiosInstance = axios.create({
  baseURL: API_URL,
});

const request = (config = {}) => axiosInstance(config);

const formatSalaryString = (salary: LeverSalary): string | undefined => {
  if (!salary.min || !salary.max) {
    return undefined;
  }
  let range = "";
  if (!salary.currency || salary.currency === "USD") {
    range = `$${salary.min.toLocaleString()} - $${salary.max.toLocaleString()}`;
  } else {
    range = `${salary.min.toLocaleString()} - ${salary.max.toLocaleString()} ${
      salary.currency
    }`;
  }
  if (!salary.interval || salary.interval === "per-year-salary") {
    return `${range} per year`;
  } else {
    return `${range} ${salary.interval}`;
  }
};

const mapLeverJobToMatchJob = (leverJob: LeverPosting): MatchJobPosting => {
  let details: MatchJobDetailsSection[] = [];
  if (leverJob.lists) {
    details = leverJob.lists.map((list) => {
      return {
        section: list.text,
        content: list.content,
      };
    });
  }

  return {
    additionalContentHtml: leverJob.additional,
    applyUrl: leverJob.applyUrl,
    brand: leverJob.categories ? leverJob.categories.brand : undefined,
    commitment: leverJob.categories
      ? leverJob.categories.commitment
      : undefined,
    descriptionHtml: leverJob.description,
    descriptionPlain: leverJob.descriptionPlain,
    details: details,
    hostedurl: leverJob.hostedurl,
    id: leverJob.id,
    location: leverJob.categories ? leverJob.categories.location : undefined,
    salaryText: leverJob.salaryRange
      ? formatSalaryString(leverJob.salaryRange)
      : undefined,
    team: leverJob.categories ? leverJob.categories.team : undefined,
    title: leverJob.text,
    workplaceType: leverJob.workplaceType,
  };
};

const groupTeamsByMatchRules = (
  groups: MatchJobPostingGrouped[]
): MatchJobPostingGrouped[] => {
  const groupedTeams: { [key: string]: MatchJobPostingGrouped } = {};
  groups.forEach((group) => {
    const teamName = TEAM_MAP[group.group]
      ? TEAM_MAP[group.group]
      : group.group;
    if (!groupedTeams[teamName]) {
      groupedTeams[teamName] = {
        group: teamName,
        jobs: [],
      };
    }
    const orderedJobs = groupedTeams[teamName].jobs
      .concat(group.jobs)
      .sort((a, b) => a.title.localeCompare(b.title));
    groupedTeams[teamName].jobs = orderedJobs;
  });
  return Object.values(groupedTeams).sort((a, b) =>
    a.group.localeCompare(b.group)
  );
};

export default {
  getJobsByTeam: async (): Promise<MatchJobPostingGrouped[]> => {
    return request({
      method: "GET",
      url: URLS.JOBS,
    }).then((res: AxiosResponse<LeverPostingsByTeam[]>) => {
      if (res.status !== 200) {
        throw new Error(`Unabled to fetch jobs lists ${res.status}`);
      } else {
        const rawJobsGroups = res.data.map((group) => {
          const matchGroup: MatchJobPostingGrouped = {
            group: group.title,
            jobs: group.postings.map((leverJob) => {
              return mapLeverJobToMatchJob(leverJob);
            }),
          };
          return matchGroup;
        });
        return groupTeamsByMatchRules(rawJobsGroups);
      }
    });
  },
  getJobById: async (jobId: string): Promise<MatchJobPosting> => {
    return request({
      method: "GET",
      url: `${URLS.JOB_DETAILS}${jobId}`,
    }).then((res: AxiosResponse<LeverPosting>) => {
      if (res.status !== 200) {
        throw new Error(`Unabled to fetch job ${res.status}`);
      } else {
        return mapLeverJobToMatchJob(res.data);
      }
    });
  },
};
