import React from "react";
import styled from "@emotion/styled";
import { Box, Button, Typography, Icon } from "@match/react-component-library";
import Link from "modules/components/Header/Link";

interface SlideInMenuProps {
  slideMenuOpen: boolean;
  toggleMenu: any;
}

export default function SlideInMenu(props: SlideInMenuProps) {
  const { slideMenuOpen } = props;

  const SlideMenuContainer = styled(Box)(() => {
    if (slideMenuOpen) {
      return {
        transition: ["transform 1s cubic-bezier(0, .52, 0, 1)"],
        transform: ["translate3d(0vw, 0, 0)"],
        overflow: ["hidden"],
        zIndex: 1000,
      };
    }
    return {
      transition: ["transform 1s cubic-bezier(0, .52, 0, 1)"],
      transform: ["translate3d(100vw, 0, 0)"],
      overflow: ["scroll"],
      zIndex: 1000,
    };
  });
  const SlideMenuLinksContainer = styled(Box)(() => {
    if (slideMenuOpen) {
      return {
        display: "flex",
      };
    }
    return {
      display: "none",
    };
  });

  return (
    <SlideMenuContainer
      backgroundColor="dark"
      width="325px"
      height="100vh"
      position="absolute"
      top="none"
      right="none"
    >
      <SlideMenuLinksContainer
        alignItems="flex-start"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="325px"
        height="50vh"
        padding="xxlarge"
      >
        <Box
          borderBottom="2px solid"
          borderColor="stroke"
          paddingBottom="smedium"
          textAlign="right"
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography color="light" fontSize="large" fontWeight="bold">
            menu
          </Typography>
          <Button minimal onClick={props.toggleMenu}>
            <Icon color="light" size="small" name="close" />
          </Button>
        </Box>
        <Button minimal onClick={props.toggleMenu}>
          <Link color="light" exact to="/">
            home
          </Link>
        </Button>
        <Button minimal onClick={props.toggleMenu}>
          <Link color="light" to="/jobs">
            careers
          </Link>
        </Button>
        <Button minimal onClick={props.toggleMenu}>
          <Link color="light" to="/perksandbenefits">
            perks + benefits
          </Link>
        </Button>
        <Button minimal onClick={props.toggleMenu}>
          <a
            href="https://mtch.com"
            rel="noopener noreferrer"
            target="_blank"
            style={{
              textDecoration: "none",
              padding: "10px",
            }}
          >
            <Typography color="light" fontSize="xsmall">
              about us
            </Typography>
          </a>
        </Button>
      </SlideMenuLinksContainer>
    </SlideMenuContainer>
  );
}
