import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@match/react-component-library';
import { FaFacebookSquare, FaInstagram, FaTwitter, FaVimeo, FaLinkedin, FaLink } from 'react-icons/fa';

interface SocialLinksProps {
  size: string;
  color: string;
}

export default function SocialLinks(props: SocialLinksProps) {
  return (
    <Box
      width={300}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
    > 
      <StyledLink
        href='https://www.facebook.com/lifeatmatch/'
        target='_blank'
      >
        <FaFacebookSquare
          size={props.size} 
          color={props.color}/>
      </StyledLink>
      <StyledLink
        href='https://twitter.com/lifeatmatch'
        target='_blank'
      >
        <FaTwitter 
          size={props.size} 
          color={props.color}/>
      </StyledLink>
      <StyledLink
        href='https://www.instagram.com/lifeatmatch/'
        target='_blank'
      >
        <FaInstagram 
          size={props.size} 
          color={props.color}/>
      </StyledLink>
      <StyledLink
        href='https://www.linkedin.com/company/match-com/'
        target='_blank'
      >
        <FaLinkedin 
          size={props.size} 
          color={props.color}/>
      </StyledLink>
      <StyledLink
        href='http://blog.match.com/'
        target='_blank'
      >
        <FaLink 
          size={props.size} 
          color={props.color}/>
      </StyledLink>
      <StyledLink
        href='https://vimeo.com/lifeatmatch'
        target='_blank'
      >
        <FaVimeo 
          size={props.size} 
          color={props.color}/>
      </StyledLink>
    </Box>
  )
}

const StyledLink = styled('a')(() => {
  return {
    textDecoration: 'none'
  }
})
