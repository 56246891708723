import React from "react";
import PageHeader from "modules/components/PageHeader";
import { Box, Heading, Typography, constants } from "@match/react-component-library";
import leverApi from "../../../utils/api-lever";
import { JobsList } from "../../components/JobsList";
import { MatchJobPostingGrouped } from "types";

const fetchMatchGroupJobs = (): Promise<MatchJobPostingGrouped[]> => {
  return leverApi
    .getJobsByTeam()
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("unable to load jobs.", err);
      return [];
    });
};

export default function Careers() {
  const [jobs, setJobs] = React.useState<MatchJobPostingGrouped[]>([]);

  React.useEffect(() => {
    fetchMatchGroupJobs().then((fetchedJobs) => {
      setJobs(fetchedJobs);
    });
  }, []);

  return (
    <React.Fragment>
      <PageHeader pageTitle="Career Opportunities" />
      <Box display="flex" justifyContent="center" marginY="xlarge">
        {jobs.length === 0 ? (
          <Box>loading</Box>
        ) : (
          <Box display="flex" maxWidth={650} width="90%" flexDirection="column">
            <Box>
              <Heading typography={constants.TYPOGRAPHY.HEADING4} priority={1}>
                Current Job Openings at Match
              </Heading>
            </Box>
            <Box paddingTop="12px">
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography fontSize="20px">Department</Typography>
                </Box>
                <Box>
                  <Typography fontSize="20px">Location and Type</Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <JobsList jobsGrouped={jobs} />
            </Box>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
}
