import React from "react";
import { MatchJobPosting } from "types";
import { Link } from "react-router-dom";
import { Box, Typography } from "@match/react-component-library";
import styled from "@emotion/styled";

interface JobDescriptionProps {
  job: MatchJobPosting;
}

const FauxButton = styled(Box)(() => {
  return {
    borderRadius: "1000px",
    borderStyle: "solid",
    borderWidth: "1px",
    height: "24px",
    paddingLeft: "16px",
    paddingRight: "16px",
    textAlign: "center",
  };
});

export function JobDescription(props: JobDescriptionProps) {
  const { job } = props;
  return (
    <Box>
      <Box>
        <h1>
          <Typography fontSize="xxlarge" fontFamily="heading">
            {job.title}
          </Typography>
        </h1>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        marginTop="small"
        alignItems="center"
      >
        <Box>
          <Typography fontSize="small">{job.location}</Typography>
        </Box>
        <Box marginLeft="small">
          <a href={`${job.applyUrl}`}>
            <FauxButton backgroundColor="ctaPrimary" height="24px">
              <Typography fontSize="xsmall" color="light">
                Apply
              </Typography>
            </FauxButton>
          </a>
        </Box>
      </Box>
      <Box>
        <Link to="/jobs">
          <Typography fontSize="xxsmall" color="link">
            View all jobs
          </Typography>
        </Link>
      </Box>
      <Box marginTop="xxlarge">
        <Typography fontSize="small">
          <div dangerouslySetInnerHTML={{ __html: job.descriptionHtml }}></div>
          {job.details.map((detail) => {
            return (
              <Box marginTop={"16px"} key={detail.section}>
                <h4>{detail.section}</h4>
                <Box marginTop={"8px"}>
                  <div
                    dangerouslySetInnerHTML={{ __html: detail.content }}
                  ></div>
                </Box>
              </Box>
            );
          })}
        </Typography>
      </Box>
      {job.salaryText && (
        <Box marginTop="24px">
          <h4>Salary Range</h4>
          <Box marginTop="8px">
            <Typography fontSize="small">{job.salaryText}</Typography>
            <br />
            <Typography fontSize="xsmall">
              Factors such as scope and responsibilities of the position,
              candidate's work experience, education/training, job-related skills,
              internal peer equity, as well as market and business considerations
              may influence base pay offered. This salary range is reflective of
              the location. This salary will be subject to a geographic adjustment
              (according to a specific city and state), if an authorization is
              granted to work outside of the location listed in this posting.
            </Typography>
          </Box>
        </Box>
      )}
      {job.additionalContentHtml && (
        <Box marginTop="24px">
          <Typography fontSize="small">
            <div
              dangerouslySetInnerHTML={{ __html: job.additionalContentHtml }}
            ></div>
          </Typography>
        </Box>
      )}
      <Box marginTop="16px" display="flex" justifyContent="center">
        <Box width="150px">
          <a href={`${job.applyUrl}`}>
            <FauxButton backgroundColor="ctaPrimary" height="24px">
              <Typography fontSize="xsmall" color="light">
                Apply
              </Typography>
            </FauxButton>
          </a>
        </Box>
      </Box>
    </Box>
  );
}
