import React from "react";
import {
  ThemeProvider,
  themeSurprisedCorgi,
  GlobalFontFace,
} from "@match/react-component-library";
import Layout from "modules/components/Layout/Layout";
import { ResponsiveContextProvider } from "utils/ResponsiveContext";
import { GlobalCssReset } from "modules/components/GlobalCssReset";
import routes from "routes";

class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={themeSurprisedCorgi}>
        <React.Fragment>
          <GlobalFontFace />
          <GlobalCssReset anchors margins padding />
          <ResponsiveContextProvider>
            <Layout>{routes}</Layout>
          </ResponsiveContextProvider>
        </React.Fragment>
      </ThemeProvider>
    );
  }
}

export default App;
