import React from 'react';
import styled from '@emotion/styled';
import { Typography } from "@match/react-component-library";
import { NavLink } from 'react-router-dom';

interface LinkProps {
  children: React.ReactNode;
  color?: any;
  exact?: boolean;
  to: string;
}

function Link({
  to,
  children,
  color = 'textPrimary',
  exact = false
}: LinkProps) {
  return (
    <StyledNavLink
      activeStyle={{
        fontWeight: "bold",
        color: "black",
      }}
      exact={exact}
      to={to}
    >
      <Typography color={color} fontSize="xsmall">
        {children}
      </Typography>
    </StyledNavLink>
  );
}

export default Link;

const StyledNavLink = styled(NavLink)(() => {
  return {
    textDecoration: 'none',
    padding: '10px',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
})
