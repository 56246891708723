import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@match/react-component-library';
import PageHeader from 'modules/components/PageHeader';

export default function JobTemplate(props: any) {
  return (
    <React.Fragment>
      <PageHeader 
        pageTitle='Career Opportunities' 
      />
      <Box
        paddingX='smedium'
      >
        <StyledIFrame 
          allow="geolocation" 
          data-frameborder="0" 
          data-onload="window.scrollTo(0,0)" 
          height="2500"
          id="grnhse_iframe" 
          scrolling="yes" 
          src={`https://boards.greenhouse.io/embed/job_app?for=match&amp;token=${props.match.params.id}&amp`}
          title="Greenhouse Job Board" 
          width="100%" 
        >
        </StyledIFrame>    
      </Box>
    </React.Fragment>
  )
}

const StyledIFrame = styled('iframe')(() => {
  return {
    border: 'none',
    marginTop: '50px'
  }
})