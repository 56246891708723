import React, { useState } from 'react';
import {
  RouteComponentProps
} from 'react-router-dom';
import PageHeader from 'modules/components/PageHeader';
import { Box } from '@match/react-component-library';
import leverApi from '../../../utils/api-lever';
import { JobDescription } from '../../components/JobDescription';
import { MatchJobPosting } from 'types';

interface JobDetailsProps {
  id: string;
}

export function JobDetails(props: RouteComponentProps<JobDetailsProps>) {
  const { id } = props.match.params;
  const [error, setError] = useState<boolean>(false);
  const [job, setJob] = useState<MatchJobPosting | undefined>();

  React.useEffect(() => {
    leverApi.getJobById(id)
      .then((matchJob) => {
        setJob(matchJob);
      })
      .catch((err) => {
        console.log('error encounted - ', err);
        setError(true);
      });
  }, [id]);

  return (
    <React.Fragment>
      <PageHeader pageTitle='Career Opportunities' />
      <Box display='flex' marginY='xlarge' flexDirection='column' alignItems='center'>
        <Box maxWidth={650}>
          {!error && !job && (
            <div>loading</div>
          )}
          {!error && job && (
            <JobDescription job={job} />
          )}
          {error && (
            <h1>
              This job is no longer available.
            </h1>
          )}
        </Box>
      </Box>
    </React.Fragment>
  )
}
