import React from "react";
import styled from "@emotion/styled";
import useWindowSize from "utils/useWindowSize";
import PageHeader from "modules/components/PageHeader";
import PerksAndBenefitsEntry from "modules/components/PerksAndBenefitsEntry";
import { PERKS_BENEFITS_ENTRIES } from "static-data/perks-benefits-entries";
import SlidePhotoEntry from "modules/components/SlidePhotoEntry";
import { SLIDE_PHOTO_ENTRIES } from "static-data/slide-photo-entries";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Typography } from "@match/react-component-library";
import { BP } from "breakpoints";

export default function PerksAndBenefits() {
  const [width] = useWindowSize();

  let sliderSettings = {
    arrows: false,
    autoplay: true,
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 300,
  };

  const viewPerks = PERKS_BENEFITS_ENTRIES.filter(
    (entry) => entry.type === "perk"
  ).map((entry) => (
    <PerksAndBenefitsEntry
      entryDescription={entry.description}
      entryImage={entry.image}
      entryTitle={entry.title}
      key={entry.id}
    />
  ));

  const viewBenefits = PERKS_BENEFITS_ENTRIES.filter(
    (entry) => entry.type === "benefit"
  ).map((entry) => (
    <PerksAndBenefitsEntry
      entryDescription={entry.description}
      entryImage={entry.image}
      entryTitle={entry.title}
      key={entry.id}
    />
  ));

  return (
    <React.Fragment>
      <PageHeader pageTitle="Perks + Benefits" />
      <Box alignItems="center" display="flex" flexDirection="column">
        <StyledSlider {...sliderSettings}>
          {SLIDE_PHOTO_ENTRIES.map((entry) => (
            <SlidePhotoEntry
              entryTitle={entry.title}
              entryImage={entry.image}
              key={entry.id}
            />
          ))}
        </StyledSlider>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          marginTop="xxxlarge"
          width="90vw"
        >
          <Typography fontSize="xxlarge" fontFamily="heading">
            PERKS
          </Typography>
          <Box
            alignItems="center"
            display="flex"
            flexDirection={width <= BP.MEDIUM ? "column" : "row"}
            flexWrap={width > BP.MEDIUM ? "wrap" : undefined}
            justifyContent="center"
            marginTop="xlarge"
            width={width <= BP.XLARGE ? "80vw" : "70vw"}
          >
            {viewPerks}
          </Box>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          marginTop="xxxlarge"
          width="90vw"
        >
          <Typography fontSize="xxlarge" fontFamily="heading">
            BENEFITS
          </Typography>
          <Box
            alignItems="center"
            display="flex"
            flexDirection={width <= BP.MEDIUM ? "column" : "row"}
            flexWrap={width > BP.MEDIUM ? "wrap" : undefined}
            justifyContent="center"
            marginTop="xlarge"
            width={width <= BP.XLARGE ? "80vw" : "70vw"}
          >
            {viewBenefits}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

const StyledSlider = styled(Slider)(() => {
  return {
    width: "375px",
    [`@media (min-width: ${BP.SMALL}px)`]: {
      width: "475px",
    },
    [`@media (min-width: ${BP.MEDIUM}px)`]: {
      width: "600px",
    },
    [`@media (min-width: ${BP.LARGE}px)`]: {
      width: "800px",
    },
    [`@media (min-width: ${BP.XLARGE}px)`]: {
      width: "1000px",
    },
  };
});
